import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Header from "../components/layouts/header";
import GatsbyImage from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import css from "@emotion/css";

function matchCurrentProduct(element, currentProductSlug) {
  return element.node.slug === currentProductSlug;
}

const getNextProductPageData = (currentProductSlug, productpages) => {
  // get the slug and title of the next page.
  // Assumes that the order in the response from CFL is the correct order.
  const currentIndex = productpages.findIndex(element =>
    matchCurrentProduct(element, currentProductSlug)
  );

  const arrIndex =
    currentIndex < productpages.length - 1 ? currentIndex + 1 : 0;

  return productpages[arrIndex];
};

const getPrevProductPageData = (currentProductSlug, productpages) => {
  // get the slug and title of the next page.
  // Assumes that the order in the response from CFL is the correct order.
  const currentIndex = productpages.findIndex(element =>
    matchCurrentProduct(element, currentProductSlug)
  );

  const arrIndex =
    currentIndex !== 0 ? currentIndex - 1 : productpages.length - 1;

  return productpages[arrIndex];
};

export default function ProductPage({
  data: {
    allContentfulProduct: { edges },
    contentfulProduct: {
      pageTitle,
      metaDescription,
      BannerMain,
      SummaryContent,
      bannerSecondary,
      bannerTertiary,
      problem,
      solution,
      results,
      slug
    }
  }
}) {
  const nextProductData = getNextProductPageData(slug, edges);
  const prevProductData = getPrevProductPageData(slug, edges);
  return (
    <>
      <Helmet>
        <title>Product | {pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content={`Ratio, fintech, finance, software, products, Ratio's products, ${pageTitle}`}
        />
      </Helmet>
      <Header light />
      <div className="container py-3">
        <Link to="/products/" className="btn btn-lg btn-outline-dark mb-3">
          {"←"} Back
        </Link>
      </div>
      {BannerMain && (
        <GatsbyImage fluid={BannerMain.fluid} alt={BannerMain.title} />
      )}
      <section
        css={css`
          padding-top: 5rem;
          padding-bottom: 5rem;
        `}
      >
        <div
          className="container"
          css={css`
            h1,
            h2 {
              font-family: "futura-pt", sans-serif;
              font-weight: 600;

              margin-bottom: 2rem;
              color: var(--primary);
              column-span: all;
              @media (min-width: 992px) {
                max-width: 60%;
              }
            }
            h2 {
              font-size: 36px;
            }
            h3 {
              font-size: 28px;
            }
          `}
        >
          <div className="row">
            <div
              className="col-md-10 offset-md-1"
              dangerouslySetInnerHTML={{ __html: SummaryContent.md.html }}
            />
          </div>
        </div>
      </section>
      {bannerSecondary && (
        <GatsbyImage
          fluid={bannerSecondary.fluid}
          alt={bannerSecondary.title}
        />
      )}
      <section
        css={css`
          padding-top: 4rem;
          padding-bottom: 4rem;
          h1,
          h2,
          h3 {
            font-family: "futura-pt", sans-serif;
            font-weight: 500;
            font-size: 38px;
          }
        `}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-md-6"
              dangerouslySetInnerHTML={{ __html: problem.md.html }}
            />
            <div
              className="col-md-6"
              dangerouslySetInnerHTML={{ __html: solution.md.html }}
            />
          </div>
        </div>
      </section>
      {bannerTertiary && (
        <div className="container">
          <GatsbyImage
            fluid={bannerTertiary.fluid}
            alt={bannerTertiary.title}
          />
        </div>
      )}
      <section
        css={css`
          padding-top: 4rem;
          padding-bottom: 4rem;
        `}
      >
        <div
          css={css`
            columns: 1;
            h1,
            h2,
            h3 {
              column-span: all;
            }

            @media (min-width: 768px) {
              ${slug === 'ratio-champagne' || slug === 'remora' ? 'columns: 1;' : 'columns: 2;'}
              column-fill: balance;
            }
          `}
          className="container"
          dangerouslySetInnerHTML={{ __html: results.md.html }}
        />
      </section>

      <section
        css={css`
          padding-bottom: 4rem;
        `}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <BackgroundImage
                fadeIn="soft"
                fluid={nextProductData.node.BannerMain.fluid}
                style={{ height: "300px" }}
              >
                <div
                  className="row "
                  css={css`
                    position: absolute;
                    width: calc(100% - 4rem);
                    bottom: 2rem;
                    left: 2.75rem;
                    height: 310px;
                    /* @sm */
                    @media (max-width: 400px) {
                      width: calc(100% - 2rem);
                      left: 2rem;
                    }
                    @media (max-width: 768px) {
                      height: 190px;
                    }
                    @media (min-width: 768px) {
                      bottom: 0rem;
                      left: 2.75rem;
                      right: 4rem;
                      height: 220px;
                    }
                  `}
                >
                  <div className="col-12 text-right">
                    <h2
                      css={css`
                        color: #fff;
                        font-family: "futura-pt", sans-serif;
                        font-size: 38px;
                        font-weight: 500;
                        line-height: 48px;
                        margin-bottom: 2rem;
                        @media (max-width: 400px) {
                          font-size: 30px;

                          line-height: 40px;
                        }
                      `}
                    >
                      {nextProductData.node.title}
                    </h2>
                    <Link
                      css={css`
                        float: left;
                        font-size: 18px;
                        @media (max-width: 400px) {
                          font-size: 16px;
                        }
                        @media (min-width: 768px) {
                          font-size: 22px;
                        }
                        font-weight: 600;
                        display: inline-block;
                        line-height: 27px;
                        color: #fff;
                        &:hover {
                          color: #fff;
                        }
                        padding: 0.75em 1.5em;
                        border: 4px solid #ffffff;
                        border-radius: 4px;
                        white-space: nowrap;
                      `}
                      to={`/products/${prevProductData.node.slug}/`}
                    >
                      Back
                    </Link>
                    <Link
                      css={css`
                        font-size: 18px;
                        @media (max-width: 400px) {
                          font-size: 16px;
                        }
                        @media (min-width: 768px) {
                          font-size: 22px;
                        }
                        font-weight: 600;
                        display: inline-block;
                        line-height: 27px;
                        color: #fff;
                        &:hover {
                          color: #fff;
                        }
                        padding: 0.75em 1.5em;
                        border: 4px solid #ffffff;
                        border-radius: 4px;
                        white-space: nowrap;
                      `}
                      to={`/products/${nextProductData.node.slug}/`}
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export const Query = graphql`
  query($slug: String) {
    contentfulProduct(slug: { eq: $slug }) {
      pageTitle: title
      metaDescription
      slug
      BannerMain {
        title
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      bannerTertiary {
        title
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      bannerSecondary {
        title
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      SummaryContent {
        md: childMarkdownRemark {
          html
        }
      }
      problem {
        md: childMarkdownRemark {
          html
        }
      }
      solution {
        md: childMarkdownRemark {
          html
        }
      }
      results {
        md: childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          slug
          title
          BannerMain {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
